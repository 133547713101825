// apiConfig.js
//const API_BASE_URL = 'http://0.0.0.0:8000'; // Reemplaza con la URL base de tu API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

export const API_URLS = {
    MAIN: `${API_BASE_URL}/`,
    DOCS: `${API_BASE_URL}/docs`,
    AUTH_LOGIN: `${API_BASE_URL}/login`,
    GET_USER_SESSION: `${API_BASE_URL}/get_user_session`,
    GET_CATEGORIES: `${API_BASE_URL}/get_categories`,
    UPDATE_CURRENT_PROPERTY: (property_id) => `${API_BASE_URL}/change_current_property/${property_id}`,
    NEW_CATEGORY: `${API_BASE_URL}/new_category`,
    NEW_ROOM: `${API_BASE_URL}/new_room`,
    EDIT_ROOM: (room_id) => `${API_BASE_URL}/edit_room/${room_id}`,
    EDIT_CATEGORY: (category_id) => `${API_BASE_URL}/edit_category/${category_id}`,
    GET_ROOMS: (category_id) => `${API_BASE_URL}/get_rooms/${category_id}`,
    GET_ALL_ROOMS: `${API_BASE_URL}/get_all_rooms`,
    EDIT_RULE: (rule_id) => `${API_BASE_URL}/edit_rule/${rule_id}`,
    GET_RULES: `${API_BASE_URL}/get_rules`,
    NEW_RULE: `${API_BASE_URL}/new_rule`,
    RESET_RULES: `${API_BASE_URL}/reset_rules`,
    APPLY_CLEANINGS_ALL_DAY: `${API_BASE_URL}/apply_cleaning_all_days`,
    NEW_CLEANING: `${API_BASE_URL}/new_cleaning`,
    DELETE_RULE: (rule_id) => `${API_BASE_URL}/delete_rule/${rule_id}`,
    GET_CLEANINGS: (start_date,end_date) => `${API_BASE_URL}/get_cleanings/${start_date}/${end_date}`,
    GET_TIMELINE: (date_timeline) => `${API_BASE_URL}/get_timeline/${date_timeline}`,
    GET_CLEANING: (cleaning_id) => `${API_BASE_URL}/get_cleaning/${cleaning_id}`,
    CHANGE_STATUS_CLEANING: (cleaning_id) => `${API_BASE_URL}/change_status_cleaning/${cleaning_id}`,
    EDIT_CLEANING: (cleaning_id) => `${API_BASE_URL}/edit_cleaning/${cleaning_id}`,
    EXPORT_PLANNING: `${API_BASE_URL}/export_cleanings`,
    DELETE_CLEANING: (cleaning_id) => `${API_BASE_URL}/delete_cleaning/${cleaning_id}`,
    GET_RR_CONNECION: `${API_BASE_URL}/get_rr_connection`,
    EDIT_RR_CONNECTION: `${API_BASE_URL}/edit_rr_connection`,
    EIDT_RR_CREDENTIALS: `${API_BASE_URL}/edit_rr_credentials`,
    EDIT_RR_CODE: `${API_BASE_URL}/edit_rr_code`,
    GET_PEX_CONNECION: `${API_BASE_URL}/get_pex_connection`,
    EDIT_PEX_CONNECTION: `${API_BASE_URL}/edit_pex_connection`,
    GET_BOOKINGS: (start_date,end_date) => `${API_BASE_URL}/get_bookings/${start_date}/${end_date}`,
    GET_BOOKINGS_CALENDAR: (start_date,end_date) => `${API_BASE_URL}/get_bookings_calendar/${start_date}/${end_date}`,
    NEW_BOOKING: `${API_BASE_URL}/new_reservation`,
    NEW_BOOKING_TO_RR: `${API_BASE_URL}/new_reservation_to_rr`,
    CANCEL_BOOKING_TO_RR: (reservation_id) => `${API_BASE_URL}/cancel_reservation_to_rr/${reservation_id}`,
    GET_RESERVATION: (reservation_id) => `${API_BASE_URL}/get_reservation/${reservation_id}`,
    EDIT_RESERVATION: (reservation_id) => `${API_BASE_URL}/edit_reservation/${reservation_id}`,
    NEW_PEX_MAPPING: `${API_BASE_URL}/new_pex_mapping`,
    GET_PEX_MAPPINGS: `${API_BASE_URL}/get_pex_mappings`,
    EDIT_PEX_MAPPING: (pex_mapping_id) => `${API_BASE_URL}/edit_pex_mapping/${pex_mapping_id}`,
    GET_INVENTORY: (start_date,end_date) => `${API_BASE_URL}/get_inventory/${start_date}/${end_date}`,
    GET_INVENTORY_GAPS: (start_date,end_date) => `${API_BASE_URL}/get_inventory_gaps/${start_date}/${end_date}`,
    GET_INVENTORY_GAP: (inventory_gap_id) => `${API_BASE_URL}/get_inventory_gap/${inventory_gap_id}`,
    GET_INVENTORY_ALERTS: (date_timeline) => `${API_BASE_URL}/get_inventory_alerts/${date_timeline}`,
    GET_CONNECTION_HISTORY: `${API_BASE_URL}/get_connection_history`,
    NEW_BLOCK_TO_RR: `${API_BASE_URL}/new_block_to_rr`,
    CANCEL_BLOCK_TO_RR: (block_id) => `${API_BASE_URL}/cancel_block/${block_id}`,
    GET_BLOCK: (block_id) => `${API_BASE_URL}/get_block/${block_id}`,


};
