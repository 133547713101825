import React, { useContext, useRef, useEffect, useState } from 'react';
import ModalAllProperties from './modalAllProperties';
import { API_URLS } from '../api/apiConfig';
import { postWithAuth } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../base/context/authcontext';


const MenuProfileItem = ({ userData }) => {

    const [showMenuProfile, setShowMenuProfile] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef(null);
    const anchorRef = useRef(null);
    const { logout } = useAuth();
    const navigate = useNavigate();


    const toggleDropdown = () => {
        setShowMenuProfile(!showMenuProfile);
    };

    const handleLogout = () => {
        logout();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenuProfile(false);
            }
        };

        if (showMenuProfile) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenuProfile]);

    useEffect(() => {
        if (showMenuProfile) {
            // Obtenemos las coordenadas del elemento anchor
            const anchorRect = anchorRef.current.getBoundingClientRect();
            const elementWidth = anchorRef.current.offsetWidth;
            // Calculamos la posición del menú
            const screenWidth = window.innerWidth;
            const menuX = anchorRect.left - screenWidth + elementWidth;
            const menuY = anchorRect.top + anchorRect.height + 10;
            setMenuPosition({ x: menuX, y: menuY });
        }
    }, [showMenuProfile]);

    return (
        <>  

            <div className="d-flex align-items-center me-4">
                <div className="app-navbar-item me-1 me-lg-5" id="kt_header_user_menu_toggle">

                    <div ref={anchorRef} onClick={toggleDropdown} className="cursor-pointer symbol symbol-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                        <img src="/media/avatars/blank.png" alt="user"></img>
                    </div>

                    <div
                        ref={menuRef}
                        style={{ transform: `translate3d(${menuPosition.x}px, ${menuPosition.y}px, 0)` }}
                        className={`menu-profile menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${showMenuProfile ? 'show' : ''}`}
                        data-kt-menu="true">
                        <div className="menu-item px-3">
                            <div className="menu-content d-flex align-items-center px-3">
                                <div className="symbol symbol-50px me-5">
                                    <img alt="Logo" src="/media/avatars/blank.png"></img>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="fw-bold d-flex align-items-center fs-5">
                                        {userData.name} {userData.last_name}
                                            <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                                            {userData.current_property.property_name}
                                            </span>
                                    </div>

                                    <a className="fw-semibold text-muted fs-7">
                                        {userData.email}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="separator my-2"></div>

                        <div className="menu-item px-5">
                            <a onClick={handleLogout} className="menu-link px-5">
                                Cerrar Sesión
                            </a>
                        </div>
                    </div>

                </div>

                <div className="d-none d-lg-block m-0">
                    <a className="text-gray-800 fs-4 fw-bold">{userData.name} {userData.last_name}</a>
                        <span className="text-gray-500 fw-semibold d-block"> {userData.current_property.property_name} </span>
                </div>

            </div>

        </>
    );

}

const ChangeProperties = ({ userData }) => {

    const [showMenuProperties, setShowMenuProperties] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef(null);
    const anchorRef = useRef(null);

    const [isChangingProperties, setIsChangingProperties] = useState(false);

    const toggleDropdown = () => {
        setShowMenuProperties(!showMenuProperties);
    };

    const handlePropertyClick = async (property_id) => {
        try {
            setIsChangingProperties(true)
            const response = await postWithAuth(API_URLS.UPDATE_CURRENT_PROPERTY(property_id));
            if (response.message === 'success') {
                setIsChangingProperties(false)
                window.location.reload();
            } else {
                console.error('Error al actualizar la propiedad:', response.error);
            }
        } catch (error) {
            console.error('Error al realizar la llamada a la API:', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenuProperties(false);
            }
        };

        if (showMenuProperties) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenuProperties]);

    useEffect(() => {
        if (showMenuProperties) {
            // Obtenemos las coordenadas del elemento anchor
            const anchorRect = anchorRef.current.getBoundingClientRect();
            const elementWidth = anchorRef.current.offsetWidth;
            // Calculamos la posición del menú
            const screenWidth = window.innerWidth;
            const menuX = anchorRect.left - screenWidth + elementWidth;
            const menuY = anchorRect.top + anchorRect.height + 10;
            setMenuPosition({ x: menuX, y: menuY });
        }
    }, [showMenuProperties]);

    return (
        <>

            <div ref={anchorRef} onClick={toggleDropdown} className="color-background-change-properties btn btn-icon btn-custom btn-light-info btn-active-light-info w-40px h-40px me-4" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                <i className="fa-solid fa-building text-color-azul-livensa fs-2x"></i>
            </div>

            <div style={{ transform: `translate3d(${menuPosition.x}px, ${menuPosition.y}px, 0)` }}
                ref={menuRef}
                className={`menu-properties menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px ${showMenuProperties ? 'show' : ''}`}
                data-kt-menu="true">
                <div className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10">
                    <h3 className="text-color-azul-livensa fw-semibold">
                        Tus propiedades
                    </h3>
                    {isChangingProperties && (
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    )}
                </div>
                <div className="row g-0">
                {userData.properties_list.map((property, index) => (
                    <div className="col-6" key={index}>
                        <a onClick={() => handlePropertyClick(property.property_id)} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
                            <i className="fa-solid fa-up-right-from-square fs-2x text-color-azul-livensa mb-2"></i>
                            <span className="fs-5 fw-semibold text-gray-800 mb-0 text-center">{property.property_name}</span>
                            <span className="fs-7 text-gray-500"></span>
                            
                        </a>
                        
                    </div>
                ))}
                </div>
                <div className="py-2 text-center border-top">
                    <a href="/keen/demo8/pages/user-profile/activity.html" data-bs-toggle="modal" data-bs-target="#modal_all_properties" className="btn btn-color-gray-600 btn-active-color-primary">
                        Ver todas
                        <i className="ki-outline ki-arrow-right fs-5"></i>        </a>
                </div>
            </div>
            <ModalAllProperties userData={userData}/>
        </>

    );

}

const Notifications = ({ userData }) => {

    const [showMenuProperties, setShowMenuProperties] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef(null);
    const anchorRef = useRef(null);

    const [isChangingProperties, setIsChangingProperties] = useState(false);

    const toggleDropdown = () => {
        setShowMenuProperties(!showMenuProperties);
    };

    const handlePropertyClick = async (property_id) => {
        try {
            setIsChangingProperties(true)
            const response = await postWithAuth(API_URLS.UPDATE_CURRENT_PROPERTY(property_id));
            if (response.message === 'success') {
                setIsChangingProperties(false)
                window.location.reload();
            } else {
                console.error('Error al actualizar la propiedad:', response.error);
            }
        } catch (error) {
            console.error('Error al realizar la llamada a la API:', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenuProperties(false);
            }
        };

        if (showMenuProperties) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenuProperties]);

    useEffect(() => {
        if (showMenuProperties) {
            // Obtenemos las coordenadas del elemento anchor
            const anchorRect = anchorRef.current.getBoundingClientRect();
            const elementWidth = anchorRef.current.offsetWidth;
            // Calculamos la posición del menú
            const screenWidth = window.innerWidth;
            const menuX = anchorRect.left - screenWidth + elementWidth;
            const menuY = anchorRect.top + anchorRect.height + 10;
            setMenuPosition({ x: menuX, y: menuY });
        }
    }, [showMenuProperties]);

    return (
        <>

            <div ref={anchorRef} onClick={toggleDropdown} className="color-background-change-properties btn btn-icon btn-custom btn-light-info btn-active-light-info w-40px me-4 h-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                <i className="fa-solid fa-bell text-color-azul-livensa fs-2x">
                <span class="translate-middle text-color-azul-livensa badge badge-circle badge-notifications">1</span>
                </i>
            </div>

            <div style={{ transform: `translate3d(${menuPosition.x}px, ${menuPosition.y}px, 0)` }}
                ref={menuRef}
                className={`menu-properties menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px ${showMenuProperties ? 'show' : ''}`}
                data-kt-menu="true">
                <div className="d-flex flex-column flex-center bgi-no-repeat rounded-top py-5">
                    <h4 className="text-color-azul-livensa fw-semibold">
                        Alertas de inventario sin resolver
                    </h4>
                    {isChangingProperties && (
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    )}
                </div>
                <div className="row g-0 pb-5 px-5">
                    <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed flex-shrink-0 p-3">        
                        <div class="d-flex flex-stack flex-grow-1 p-4 flex-wrap flex-md-nowrap">
                            <div class="mb-3 mb-md-0 fw-semibold">
                            <h4 className="text-color-azul-livensa fw-semibold">
                            2025-02-02</h4>
                            </div>
                            <a href="#" > 
                                Ir al día            
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAllProperties userData={userData}/>
        </>

    );

}

const Header = ({ toggleDrawer, userData }) => {

    return (
        <>

            <div id="kt_app_header" className="app-header  d-flex ">
                <div className="app-container container-fluid d-flex align-items-stretch justify-content-between"
                    id="kt_app_header_container">

                    <div className="d-flex d-lg-none align-items-center ms-n3 me-2" title="Show sidebar menu">
                        <div className="btn btn-icon btn-active-color-primary w-35px h-35px" onClick={toggleDrawer}
                            id="kt_app_sidebar_mobile_toggle">
                            <i className="ki-outline ki-abstract-14 fs-2"></i>
                        </div>
                    </div>


                    <div className="d-flex d-lg-none align-items-center me-auto">
                        <a href="index.html">
                            <img alt="Logo" src="/media/livensa/Studios-livensaliving.png" className="h-35px" />
                        </a>
                    </div>

                    <div data-kt-swapper="true" data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_header_container'}" className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                    </div>
                    <div className="app-navbar flex-stack flex-shrink-0 mt-lg-3 " id="kt_app_aside_navbar">
                        <div className="app-navbar-item">

                            <MenuProfileItem userData={userData} />

                            <ChangeProperties userData={userData}/>

                            {/* <Notifications userData={userData} /> */}

                        </div>

                    </div>

                </div>

            </div>



        </>
    );

};

export default Header;