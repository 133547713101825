import React, { useContext, useRef, useEffect, useState } from 'react';
import ModalNewSettingsRRCode from '../components/modalNewSettingsRRCode';
import { useSettings } from '../../settings/settingsContext';
import ModalNewSettingsRRCredentials from '../components/modalNewSettingsRRCredentials';
import ModalNewMap from '../components/modalNewMap';
import ModalUpdateMap from '../components/modalUpdateMap';
import ModalEditPEXConnection from '../components/modalEditPEXConnection';


const YesSettingsPage = ({ settings, pexConnection, pexMappings }) => {

    const settingsContext = useSettings()


    return (
        <>
            
            <div className="card mb-5 mb-xxl-10">
                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <h3>Mapeo de propiedad RR-Wofy-PEX</h3>
                    </div>
                    <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                        <a onClick={() => settingsContext.openNewPexMappingModal()} className="btn btn-sm btn-primary mx-2">
                                <i className="icon-in-button-color ki-outline ki-plus"></i>
                                Nuevo Mapeo</a>
                        </div>
                    </div>
                </div>
                <div className="card-body py-10">
                    <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                            <thead>
                                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">

                                    <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" aria-label="User: activate to sort column ascending">
                                        Número Wofy
                                    </th>
                                    <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Nombre Wofy
                                    </th>
                                    <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Número PEX
                                    </th>
                                    <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Nombre PEX
                                    </th>
                                    <th className="min-w-125px sorting" rowSpan="1" colSpan="1" aria-label="Actions">Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-600 fw-semibold">
                            {pexMappings.map((pexMapping, index) => (

                                <tr key={index}>
                                    <td>
                                        {pexMapping.wofy_number}
                                    </td>
                                    <td>
                                        {pexMapping.wofy_name}
                                    </td>
                                    <td>
                                    {pexMapping.pex_number}
                                    </td>
                                    <td>
                                    {pexMapping.pex_name} 
                                    </td>
                                    <td className="">
                                        <TableMapActions 
                                            pexMapping={pexMapping}
                                        />

                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            
                        </table>
                    </div>
                <div className="row">
                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    </div>
                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                            
                        </div>
                    </div>
                </div>
            </div>
                  
                </div>
            </div>
            
            
            <div className="card mb-5 mb-xxl-10">
                <div className="card-header">
                    <div className="card-title">
                        <h3>Conexión RR</h3>
                    </div>
                    <div className="card-toolbar">
                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                            <span className="form-check-label text-muted">
                            {process.env.REACT_APP_API_ENVIRONMENT} Enviroment
                            </span>
                        </label>
                    </div>
                </div>
                <div className="card-body py-10">
                    <div className="row mb-10">
                        <div className="col-md-6 pb-10 pb-lg-0">
                            <h2>Usuario, contraseña y endpoint</h2>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                Es necesario establecer un usuario y contraseña establecido por ambas partes para securizar la información. El endpoint es la url donde el proveedor externo debe enviar la información
                            </p>
                            <p className="text-gray-800 fw-bold mb-1 fs-6 py-2">https://api.livensa.com</p>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                Usuario recepción reservas: {settings.username}
                            </p>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                Pass recepción reservas: {settings.password}
                            </p>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                Usuario envío reservas: {settings.username_send || "N/D"}
                            </p>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                Pass envío reservas: {settings.password_send || "N/D"}
                            </p>
                            <a onClick={() => settingsContext.openNewSettingsRRCredentialsModal(settings)} className="btn btn-light btn-active-light-primary">Cambiar user y pass</a>
                        </div>
                        <div className="col-md-6">
                            <h2>Código de propiedad</h2>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                El código de la propiedad, conocido por ambas partes, reconoce a la propiedad con respecto al resto de propiedades
                            </p>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                Código: {settings.hotel_code}
                            </p>
                            <a onClick={() => settingsContext.openNewSettingsRRCodeModal(settings)} className="btn btn-light btn-active-light-primary">Cambiar código</a>
                        </div>
                    </div>
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed  p-6">
                        <i className="ki-outline ki-design-1 fs-2tx text-primary me-4"></i>
                        <div className="d-flex flex-stack flex-grow-1 ">
                            <div className=" fw-semibold">
                                <div className="fs-6 text-gray-700 "> Cambiar estos valores implica una posible perdida de conexión</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-5 mb-xxl-10">
                <div className="card-header">
                    <div className="card-title">
                        <h3>Conexión PEX</h3>
                    </div>
                    <div className="card-toolbar">
                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                            <span className="form-check-label text-muted">
                            {process.env.REACT_APP_API_ENVIRONMENT} Enviroment
                            </span>
                        </label>
                    </div>
                </div>
                <div className="card-body py-10">
                    <div className="row mb-10">
                        <div className="col-md-6 pb-10 pb-lg-0">
                            <h2>Mapeo de Building</h2>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                Es necesario establecer "building name" para mapear la reserva de PEX con la propiedad en Wofy House Keeping
                            </p>
                            <p className="fs-6 fw-semibold text-gray-600 py-2">
                                
                                Building: {pexConnection ? pexConnection.building_name : 'No disponible'}
                            </p>
                            <a onClick={() => settingsContext.openEditPEXConnectionModal(pexConnection)} className="btn btn-light btn-active-light-primary">Cambiar "building name"</a>
                        </div>
                    </div>
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed  p-6">
                        <i className="ki-outline ki-design-1 fs-2tx text-primary me-4"></i>
                        <div className="d-flex flex-stack flex-grow-1 ">
                            <div className=" fw-semibold">
                                <div className="fs-6 text-gray-700 "> Cambiar estos valores implica una posible perdida de conexión</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {settingsContext.showEditPEXConnectionModal && (
                <ModalEditPEXConnection
                    onClose={() => settingsContext.closeEditPEXConnectionModal()}
                    pexConnection={settingsContext.pexConnection}
                />
            )}

            {settingsContext.showNewSettingsRRCodeModal && (
                <ModalNewSettingsRRCode
                    onClose={() => settingsContext.closeNewSettingsRRCodeModal()}
                    settings={settingsContext.settings}
                />
            )}

            {settingsContext.showNewSettingsRRCredentialsModal && (
                <ModalNewSettingsRRCredentials
                    onClose={() => settingsContext.closeNewSettingsRRCredentialsModal()}
                    settings={settingsContext.settings}
                />
            )}

            {settingsContext.showNewPexMappingModal && (
                <ModalNewMap
                    onClose={() => settingsContext.closeNewPexMappingModal()}
                />
            )}


            {settingsContext.showEditPexMappingModal && (
                <ModalUpdateMap
                    onClose={() => settingsContext.closeEditPexMappingModal()}
                    pexMapping={settingsContext.pexMapping}
                />
            )}

            

        </>
    );

};

export default YesSettingsPage;

const TableMapActions = ({pexMapping}) => {

    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef(null);
    const anchorRef = useRef(null);
    const settingsContext = useSettings()
    const [showAlert, setIshowAlert] = useState(false);
    const [message, setMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const toggleDropdown = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    useEffect(() => {
        if (showMenu) {
            const anchorRect = anchorRef.current.getBoundingClientRect();
            const elementWidth = anchorRef.current.offsetWidth;
            const screenWidth = window.innerWidth;
            const menuX = anchorRect.left - screenWidth + elementWidth;
            const menuY = anchorRect.top + anchorRect.height + 10;
            setMenuPosition({ x: menuX, y: menuY });
        }
    }, [showMenu]);

    return (
        <>
            <a
                href="#"
                className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                onClick={toggleDropdown}
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                ref={anchorRef} // Referencia al elemento anchor
            >
                Acciones
                <i className="ki-outline ki-down text-color-azul-livensa fs-5 ms-1"></i>
            </a>
            <div
                ref={menuRef}
                style={{ transform: `translate3d(${menuPosition.x}px, ${menuPosition.y}px, 0)` }}
                className={`menu-actions-table menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 ${showMenu ? 'show' : ''}`}
                data-kt-menu="true"
                data-popper-placement="bottom-end"
            >
                <div className="menu-item px-3">
                    <a onClick={() => settingsContext.openEditPexMappingModal(pexMapping)} className="menu-link px-3">
                        Editar
                    </a>
                </div>
            </div>
        </>
    );
};