import React, { useContext, useRef, useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import { Button, Modal } from 'react-bootstrap';
import { postWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';
import { getWithAuth } from '../../api/api';


const ModalBlockCreate = ({ onClose }) => {

    const [showAlert, setIshowAlert] = useState(false);
    const [alertMessage, setShowAlerMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [rooms, setListRooms] = useState([]);
    const date_now = new Date();
    const dateNowFormatted = date_now.toISOString().split('T')[0];

    const [updateBooking, setBooking] = useState({
        status: "Reserved",
        start: dateNowFormatted,
        end: dateNowFormatted,
        room_id: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                console.log("Get Rooms")
                const data = await getWithAuth(API_URLS.GET_ALL_ROOMS);
                setListRooms(data.data);
                if (data.data.length > 0) {
                    setBooking(prevState => ({
                        ...prevState,
                        room_id: data.data[0].id // Establece el ID de la primera habitación
                    }));
                }
                console.log(data.data);
            } catch (error) {
                setIsLoading(false);
                setIshowAlert(true);
                setIshowAlert("Ha ocurrido un error conectando con el servidor")
                console.error('Error getting cards:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []); //se indica 

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setBooking(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(updateBooking);
    };

    const saveBooking = async (e) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(updateBooking));
        try {
            setIsLoading(true);
            const response = await postWithAuth(
                API_URLS.NEW_BLOCK_TO_RR,
                updateBooking
            );
            if (response.message === 'success') {
                setIsLoading(false);
                window.location.reload();
            } else if (response.message === 'error') {
                setIsLoading(false);
                setIshowAlert(true);
                setShowAlerMessage(response.detail);
            } else {
                //setAlertType('danger');
                //setMessage("Error inesperado");
                //setShowAlertHeader(true);
            }
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            saveBooking();
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };

    return (
        <>
            <Modal show={true} onHide={onClose} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo bloqueo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                                <div className="spinner-grow text-color-verde-wofyrooms" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
                            <>
                    <form id="kt_modal_create_api_key_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#" data-select2-id="select2-data-kt_modal_create_api_key_form">
                        <div className="modal-body py-5 px-lg-17" data-select2-id="select2-data-108-9vb6">
                            <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_create_api_key_header" data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll" data-kt-scroll-offset="300px" data-select2-id="select2-data-kt_modal_create_api_key_scroll">
                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                    <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                                    <div className="d-flex flex-stack flex-grow-1 ">
                                        <div className=" fw-semibold">
                                            <h4 className="text-gray-900 fw-bold">Atención!</h4>
                                            <div className="fs-6 text-gray-700 ">Este bloqueo tiene efecto real en la propiedad. Será enviado a RoomRaccoon.</div>
                                        </div>
                                    </div>
                                </div>
                                {showAlert && (
                                    <div className="alert alert-danger d-flex align-items-center p-5">
                                        <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                        <div className="d-flex flex-column">
                                            <span className="mb-1 text-danger">{alertMessage}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                    <label for="exampleFormControlInput1" className="form-label">Fecha de inicio</label>
                                    <div>
                                        <Form.Control className="d-flex align-items-center" placeholder='Fecha de entrada' name="start" onChange={handleInputChange} defaultValue={updateBooking.start} type="date" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                    <label for="exampleFormControlInput1" className="form-label">Fecha de fin</label>
                                    <div>
                                        <Form.Control className="d-flex align-items-center" placeholder='Fecha de salida' name="end" onChange={handleInputChange} defaultValue={updateBooking.end} type="date" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                    <label for="exampleFormControlInput1" className="form-label">Habitación</label>
                                    <select className="form-select" onChange={handleInputChange} name="room_id" aria-label="Select example">
                                        {rooms.map(room => (
                                            <option key={room.id} value={room.id}>{room.number} {room.name}</option>
                                        ))}
                                    </select>
                                </div>   
                            </div>
                        </div>
                        <div className="modal-footer flex-center">
                            <button type="reset" onClick={onClose} className="btn btn-light me-3">
                                Cancelar
                            </button>
                            <button type="submit" onClick={handleSave} className="btn btn-primary" data-kt-indicator={`${isLoading ? 'on' : 'off'}`}>
                                <span className="indicator-label">
                                    Crear bloqueo
                                </span>
                                <span className="indicator-progress">
                                    Creando bloqueo... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                    </>
                        )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalBlockCreate;