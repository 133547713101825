import React, { createContext, useContext, useState } from 'react';

const InventoryContext = createContext();

export const InventoryProvider = ({ children }) => {

    const [selectedGap, setSelectedGap] = useState(null);
    const today = new Date();
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');
    const [settings, setSettings] = useState(null);
    const [pexMapping, setPexMapping] = useState(null);
    const [dateTimelineActive, setDateTimelineActive] = useState(today.toDateString());


    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const closeAlertHeader = () => {
        setShowAlertHeader(false);
    };


    const [showNewSettingsModal, setShowNewSettingsModal] = useState(false);
    const openNewSettingsModal = () => {
        setShowNewSettingsModal(true);
    };

    const closeNewSettingsModal = () => {
        setShowNewSettingsModal(false);
    };

    const [showNewSettingsRRCodeModal, setShowNewSettingsRRCodeModal] = useState(false);
    const openNewSettingsRRCodeModal = (settings) => {
        setSettings(settings)
        setShowNewSettingsRRCodeModal(true);
    };

    const closeNewSettingsRRCodeModal = () => {
        setShowNewSettingsRRCodeModal(false);
    };

    const [showNewSettingsRRCredentialsModal, setShowNewSettingsRRCredentialsModal] = useState(false);
    const openNewSettingsRRCredentialsModal = (settings) => {
        setSettings(settings)
        setShowNewSettingsRRCredentialsModal(true);
    };

    const closeNewSettingsRRCredentialsModal = () => {
        setShowNewSettingsRRCredentialsModal(false);
    };

    const [apiSuccess, setApiSuccessSuccess] = useState(false);
    const changeApiSuccess = () => {
        setApiSuccessSuccess(!apiSuccess);
    };

    const [showNewPexMappingModal, setShowNewPexMappingModal] = useState(false);
    const openNewPexMappingModal = () => {
        setShowNewPexMappingModal(true);
    };

    const closeNewPexMappingModal = () => {
        setShowNewPexMappingModal(false);
    };

    const [showEditPexMappingModal, setShowEditPexMappingModal] = useState(false);
    const openEditPexMappingModal = (pexMapping) => {
        setPexMapping(pexMapping)
        setShowEditPexMappingModal(true);
    };

    const closeEditPexMappingModal = () => {
        setShowEditPexMappingModal(false);
    };

    const [showGapInfoModal, setShowGapInfoModal] = useState(false);
    const openGapInfoModal = (inventory_gap_id) => {
        setSelectedGap(inventory_gap_id)
        setShowGapInfoModal(true);
    };

    const [showNewBlockModal, setShowNewBlockModal] = useState(false);
    const openNewBlockModal = () => {
        setShowNewBlockModal(true);
    };

    const closeNewBlockModal = () => {
        setShowNewBlockModal(false);
    };

    const [selectedBlock, setSelectedBlock] = useState(null);
    const [showUpdateBlockModal, setShowUpdateBlockModal] = useState(false);
        const openUpdateBlockModal = (block_id) => {
            setSelectedBlock(block_id);
            setShowUpdateBlockModal(true);
        };
        const closeUpdateBlockModal = () => {
            setShowUpdateBlockModal(false);
        };

    const closeGapInfoModal = () => {
        setShowGapInfoModal(false);
    };

    return (
        <InventoryContext.Provider value={{
            apiSuccess,
            changeApiSuccess,
            alertType,
            setAlertType,
            setMessage,
            message,
            settings,
            pexMapping,
            openAlertHeader,
            closeAlertHeader,
            showAlertHeader,
            setShowNewSettingsModal,
            showNewSettingsModal,
            openNewSettingsModal,
            closeNewSettingsModal,
            setShowNewSettingsRRCodeModal,
            showNewSettingsRRCodeModal,
            openNewSettingsRRCodeModal,
            closeNewSettingsRRCodeModal,
            setShowNewSettingsRRCredentialsModal,
            showNewSettingsRRCredentialsModal,
            openNewSettingsRRCredentialsModal,
            closeNewSettingsRRCredentialsModal,
            openEditPexMappingModal,
            closeEditPexMappingModal,
            openNewPexMappingModal,
            closeNewPexMappingModal,
            showNewPexMappingModal,
            showEditPexMappingModal,
            setPexMapping,
            setDateTimelineActive,
            dateTimelineActive,
            openGapInfoModal,
            selectedGap,
            setSelectedGap,
            showGapInfoModal,
            closeGapInfoModal,
            openNewBlockModal,
            closeNewBlockModal,
            showNewBlockModal,
            openUpdateBlockModal,
            closeUpdateBlockModal,
            showUpdateBlockModal,
            selectedBlock
        }}>
            {children}
        </InventoryContext.Provider>
    );
};

export const useInventory = () => {
    return useContext(InventoryContext);
};
